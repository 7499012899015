export const FETCH_CATEGORIES_REQUEST = "FETCH_CATEGORIES_REQUEST";
export const FETCH_CATEGORIES_SUCCESS = "FETCH_CATEGORIES_SUCCESS";
export const FETCH_CATEGORIES_FAILURE = "FETCH_CATEGORIES_FAILURE";

export const REGISTER_CATEGORY_REQUEST = "REGISTER_CATEGORY_REQUEST";
export const REGISTER_CATEGORY_SUCCESS = "REGISTER_CATEGORY_SUCCESS";
export const REGISTER_CATEGORY_FAILURE = "REGISTER_CATEGORY_FAILURE";

export const UPDATE_CATEGORY_REQUEST = "UPDATE_CATEGORY_REQUEST";
export const UPDATE_CATEGORY_SUCCESS = "UPDATE_CATEGORY_SUCCESS";
export const UPDATE_CATEGORY_FAILURE = "UPDATE_CATEGORY_FAILURE";

export const CLEAR_STATE = "CLEAR_STATE";
