import React, { useState } from "react";
import Button from "../globals/Button";
import "./auth.scss";
import TextInput from "../globals/TextInput";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
// noty
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { signup } from "../../redux/auth/authActions";

const Register = () => {
  const dispatch = useDispatch();

  const inputs = { firstname: "", lastname: "", phone: "" };
  const [inputValues, setInputValues] = useState(inputs);
  const [formErrors, setformErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  //   handle field change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValues({ ...inputValues, [name]: value });
  };

  // validate the form
  const validate = (values) => {
    const errors = {};
    if (!values.firstname) {
      errors.firstname = "First name is required!";
    }
    if (!values.lastname) {
      errors.lastname = "Last name is required!";
    }

    if (!values.phone) {
      errors.phone = "Phone number is required!";
    }
    return errors;
  };
  let submitForm = (e) => {
    e.preventDefault();
    setformErrors(validate(inputValues));
    setIsSubmit(true);

    if (
      Object.keys(formErrors).length === 0 &&
      inputValues.firstname !== "" &&
      inputValues.lastname !== "" &&
      // inputValues.email !== "" &&
      inputValues.phone !== ""
    ) {
      toast.info("Signing you up, please wait...");
      dispatch(signup(inputValues)).then((status) => {
        if (status) {
          setIsSubmit(false);
          toast.success("Welcome to misana! Registration was successfull.");
          window.location.href = "./";
        } else {
          toast.error("Failed to create your account. Please retry.");
          setIsSubmit(false);
        }
      });
    } else {
      toast.error("Fill in the form correctly!");
      setIsSubmit(false);
    }
  };
  return (
    <div className="auth">
      <div className="auth__box">
        <div className="auth__box__header">
          <div className="title-large font-20 text-blue">Sign up</div>
        </div>
        <form onSubmit={(e) => submitForm(e)}>
          <div className="auth__box__body">
            <TextInput
              onChange={handleChange}
              value={inputValues.firstname}
              inputError={formErrors.firstname}
              type="text"
              name="firstname"
              label="First name"
            />
            <TextInput
              onChange={handleChange}
              value={inputValues.lastname}
              inputError={formErrors.lastname}
              type="text"
              name="lastname"
              label="Last name"
            />
            {/* <TextInput
              onChange={handleChange}
              value={inputValues.email}
              inputError={formErrors.email}
              type="email"
              name="email"
              label="Email"
            /> */}
            <TextInput
              onChange={handleChange}
              value={inputValues.phone}
              inputError={formErrors.phone}
              type="number"
              name="phone"
              label="Phone number"
            />
          </div>
          <div className="auth__box__footer">
            <Button
              text={
                isSubmit ? (
                  <i className="fa fa-spinner fa-spin font-12 text-primary"></i>
                ) : (
                  "Sign up"
                )
              }
              disabled={isSubmit ? true : false}
              className="bg-blue btn btn-success pull-right"
              type="submit"
            />
          </div>
        </form>
        <div>
          <p className="auth__box__footer__links">
            Already registered?{" "}
            <Link to="/login" className="links">
              {" "}
              Login
            </Link>
          </p>
        </div>
      </div>
      <ToastContainer theme="dark" />
    </div>
  );
};

export default Register;
