import React from "react";
import "./no-items.scss";
import img from "../../../src/assets/images/clearcart.png";

function NoItems() {
  return (
    <div className="no-items-found">
      <img src={img} alt="" />
      <p>No items found</p>
    </div>
  );
}

export default NoItems;
