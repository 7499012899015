import axios from "axios";
import { BASE_URL } from "../api";
import {
  ADD_PRODUCT_FAILURE,
  ADD_PRODUCT_REQUEST,
  ADD_PRODUCT_SUCCESS,
  CLEAR_STATE,
  FETCH_PRODUCTS_FAILURE,
  FETCH_PRODUCTS_REQUEST,
  FETCH_PRODUCTS_SUCCESS,
  UPDATE_PRODUCT_FAILURE,
  UPDATE_PRODUCT_IMAGE_FAILURE,
  UPDATE_PRODUCT_IMAGE_REQUEST,
  UPDATE_PRODUCT_IMAGE_SUCCESS,
  UPDATE_PRODUCT_REQUEST,
  UPDATE_PRODUCT_SUCCESS,
} from "./productActionTypes";

// set the actions

// 1. fetch the products
export const fetchProductsRequest = () => {
  return {
    type: FETCH_PRODUCTS_REQUEST,
  };
};

// 2. set and store the products in case of success
export const fetchProductsSuccess = (products) => {
  return {
    type: FETCH_PRODUCTS_SUCCESS,
    payload: products,
  };
};

// set the error message in case of an error
export const fetchProductsFailure = (error) => {
  return {
    type: FETCH_PRODUCTS_FAILURE,
    payload: error,
  };
};

// 4.add a product
export const addProductRequest = () => {
  return {
    type: ADD_PRODUCT_REQUEST,
  };
};

// 5. set and store the product in case of success
export const addProductSuccess = (product) => {
  return {
    type: ADD_PRODUCT_SUCCESS,
    payload: product,
  };
};

// set the error message in caseof an error
export const addProductFailure = (error) => {
  return {
    type: ADD_PRODUCT_FAILURE,
    payload: error,
  };
};

// update a product
export const updateProductRequest = () => {
  return {
    type: UPDATE_PRODUCT_REQUEST,
  };
};

// 5. set and store the product in case of success
export const updateProductSuccess = (msg) => {
  return {
    type: UPDATE_PRODUCT_SUCCESS,
    payload: msg,
  };
};

// set the error message in caseof an error
export const updateProductFailure = (error) => {
  return {
    type: UPDATE_PRODUCT_FAILURE,
    payload: error,
  };
};

// update product image
export const updateProductImageRequest = () => {
  return {
    type: UPDATE_PRODUCT_IMAGE_REQUEST,
  };
};

// 5. set and store the product in case of success
export const updateProductImageSuccess = (msg) => {
  return {
    type: UPDATE_PRODUCT_IMAGE_SUCCESS,
    payload: msg,
  };
};

// set the error message in caseof an error
export const updateProductImageFailure = (error) => {
  return {
    type: UPDATE_PRODUCT_IMAGE_FAILURE,
    payload: error,
  };
};

// clear the state
export const clearStateRequest = () => {
  return {
    type: CLEAR_STATE,
  };
};

// fetch the products using axios
export const fetchProducts = () => {
  return (dispatch) => {
    dispatch(fetchProductsRequest);
    axios
      .get(`${BASE_URL}/api/products`)
      .then((response) => {
        const products = response.data.products;
        dispatch(fetchProductsSuccess(products));
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(fetchProductsFailure(errorMsg));
      });
  };
};

export function clearState() {
  return (dispatch) => {
    dispatch(clearStateRequest);
  };
}

// add a product
export function addproduct(data) {
  return function action(dispatch) {
    dispatch(addProductRequest);

    const request = axios({
      method: "POST",
      url: BASE_URL + "/api/addProduct",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      data: data,
    });

    return request
      .then((response) => {
        if (response.data.status === 200) {
          const product = response.data.data;
          dispatch(fetchProducts());
          dispatch(addProductSuccess(product));
        } else {
          const errorMsg = response.data.message;
          dispatch(addProductFailure(errorMsg));
        }
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(addProductFailure(errorMsg));
      });
  };
}

// update product
export function updateProduct(data) {
  return function action(dispatch) {
    dispatch(updateProductRequest);

    const request = axios.put(
      BASE_URL + "/api/update_product/" + data.product_id,
      data
    );

    return request
      .then((response) => {
        if (response.data.status === 200) {
          const success = response.data.message;
          dispatch(fetchProducts());
          dispatch(updateProductSuccess(success));
        } else {
          const errorMsg = response.data.message;
          dispatch(updateProductFailure(errorMsg));
        }
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(updateProductFailure(errorMsg));
      });
  };
}

// update product image
export function updateProductImage(data, id) {
  return function action(dispatch) {
    dispatch(updateProductImageRequest);

    const request = axios.post(
      BASE_URL + "/api/update_product_image/" + id,
      data
    );

    return request
      .then((response) => {
        if (response.data.status === 200) {
          const success = response.data.message;
          dispatch(fetchProducts());
          dispatch(updateProductImageSuccess(success));
        } else {
          const errorMsg = response.data.message;
          dispatch(updateProductImageFailure(errorMsg));
        }
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(updateProductImageFailure(errorMsg));
      });
  };
}
