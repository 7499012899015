import React from "react";
import logo from "../../assets/images/logo.png";

function SystemFooter() {
  return (
    <div className="flex flex-col justify-between">
      <div className="bg-mainColor px-8 py-4 flex flex-row sm:flex-col justify-between">
        <div className="sm:w-full">
          <img className="w-52 sm:w-full mb-4" src={logo} alt="Logo" />
          <p className="text-xl text-color-grey-500 my-2">
            Healthy, Effective. Convenient
          </p>
          <div className="flex flex-col space-y-2 mt-2">
            <div className="flex-row items-center space-x-2">
              <i className="fa fa-phone text-white font-8"></i>{" "}
              <p className="text-white font-8">+254 769 610 382</p>
            </div>
            <div className="flex-row items-center ">
              <i className="fa fa-envelope text-white font-7 mr-5"></i>{" "}
              <p className="text-white font-8">info@misana.co.ke</p>
            </div>
            <div className="flex-row items-center ">
              <i className="fa fa-map text-white font-7 mr-5"></i>{" "}
              <p className="text-white font-8">Nairobi, Kenya</p>
            </div>
          </div>
        </div>
        <div className="flex flex-col space-y-2">
          <p className="text-lg font-bold text-color-grey-500 my-2">
            Quick links
          </p>
          <a className="text-white text-sm" href="/">
            Home
          </a>
          <a className="text-white text-sm" href="/login">
            Login
          </a>
          <a className="text-white text-sm" href="/register">
            Create account
          </a>
          <a className="text-white text-sm" href="/cart">
            Cart
          </a>
        </div>
        <div className="flex flex-col space-y-2">
          <p className="text-lg font-bold text-color-grey-500 my-2">
            Connect with Us
          </p>
          <div className="flex-row items-center justify-start sm:justify-start">
            <a
              href="https://www.facebook.com/profile.php?id=100081747921165"
              target="_blank"
            >
              <i className="fa fa-facebook text-white font-8 mr-10 cursor-pointer"></i>
            </a>
            <a
              href="https://www.instagram.com/misanahealth?igsh=aDVxbGFxMmZsMGc3"
              target="_blank"
            >
              <i className="fa fa-instagram text-white font-8 mr-10 cursor-pointer"></i>
            </a>
          </div>
          <a
            className="text-white text-sm"
            target="__blank"
            href="https://misana.co.ke/"
          >
            Visit our website
          </a>
        </div>
      </div>
      <div className="flex w-full h-16 bg-black items-center justify-center">
        <p className="text-color-green-light text-sm">
          © {new Date().getFullYear()} Misana Foods &nbsp; | &nbsp;
        </p>
        <p className="text-white text-sm"> All rights Reserved</p>
      </div>
    </div>
  );
}

export default SystemFooter;
