import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { fetchOrders } from "../../redux/cart/cartActions";
import DataTable from "../datatable/DataTable";
import Modal from "../globals/Modal";
import OrderStatus from "../globals/OrderStatus";
import "./profile.scss";

function Myorders() {
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);
  const [items, setItems] = useState();
  const [orderId, setOrderId] = useState();

  let showItems = (orderItems, orderID) => {
    setShowModal(true);
    setItems(orderItems);
    setOrderId(orderID);
    document.body.style.overflow = "hidden";
  };
  let hideItems = () => {
    setShowModal(false);
    setItems();
    setOrderId();
    document.body.style.overflow = "auto";
  };

  const user = useSelector((state) =>
    state.auth?.userInfo && state.auth.userInfo ? state.auth.userInfo : {}
  );
  const token = useSelector((state) =>
    state.auth.token ? state.auth.token : ""
  );

  useEffect(() => {
    let data = {
      customer_id: user.xced_id,
      token: token,
    };
    dispatch(fetchOrders(data));
  }, [token, user.xced_id, dispatch]);

  const cartItems = useSelector((state) => (state.cart ? state.cart : []));
  const orders = cartItems.orders;

  let orderHeaders = [
    "Order ID",
    "Date",
    "Products",
    "Total",
    "Location",
    "Status",
  ];
  let tableRows = [];
  if (cartItems.orders) {
    for (let i = 0; i < orders.length; i++) {
      tableRows.push({
        order_id: orders[i].order_id,
        created_at: moment(orders[i].created_at).format("llll"),
        products: (
          <div
            onClick={() =>
              showItems(JSON.parse(orders[i].products), orders[i].order_id)
            }
            title="View items"
            className="links"
          >
            {JSON.parse(orders[i].products).length} items
          </div>
        ),
        total: "Kshs." + orders[i].total.toFixed(2),
        location: orders[i].location,
        status: <OrderStatus status={orders[i].order_status} />,
      });
    }
  } else {
    tableRows = [];
  }

  return (
    <div className="profile">
      <Modal
        title={`Order Items: ID(${orderId})`}
        show={showModal}
        handleClose={hideItems}
      >
        {items &&
          items.map((item) => {
            return (
              <div
                key={item.product_id}
                className="checkout-wrapper__cart-summary__item__product"
              >
                <h5>{item.product_name}</h5>
                <div className="checkout-wrapper__cart-summary__item__product__summation">
                  <p>
                    {item.quantity} item(s) x Kshs.
                    {item.product_price.toFixed(2)}
                  </p>
                  <h6>
                    Kshs.{(item.quantity * item.product_price).toFixed(2)}
                  </h6>
                </div>
              </div>
            );
          })}
      </Modal>
      <div className="product-wrapper__breadcrumb mb">
        <p>
          <Link className="links" to="/">
            Home
          </Link>
        </p>
        <i className="fa fa-chevron-right breadcrumb-separator"></i>
        <p>
          <Link className="links" to="/profile">
            My account
          </Link>
        </p>
        <i className="fa fa-chevron-right breadcrumb-separator"></i>
        <p>Order history</p>
      </div>
      <div className="product-wrapper__category-title">
        <h2>Order history</h2>
      </div>
      <div className="clearfix"></div>
      <div className="table-wrapper">
        <DataTable headers={orderHeaders} data={tableRows} />
      </div>
    </div>
  );
}

export default Myorders;
