import React, { useEffect, useState } from "react";
import "./topnav.scss";
import logo from "../../assets/images/logo.png";
import avatar from "../../assets/images/avatar.jpg";
import { fetchCategories } from "../../redux/product_categories/categoryActions";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { logout } from "../../redux/auth/authActions";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
// noty
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const TopNav = ({ openSidebar, fetchCategories, productCategories }) => {
  const dispatch = useDispatch();
  const token = useSelector((state) =>
    state.auth.token ? state.auth.token : ""
  );
  // const errorMessage = useSelector((state) =>
  //   state.auth.error ? state.auth.error : ""
  // );
  // const successMessage = useSelector((state) =>
  //   state.auth.success ? state.auth.success : ""
  // );
  // let notify = () => {
  //   let n = errorMessage ? toast.error(errorMessage) : "";
  //   let s = successMessage ? toast.success(successMessage) : "";
  //   return n, s;
  // };
  const logoutAction = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="dawa-prompt">
            <div className="dawa-prompt__header">
              <h1>Your session will be ended.</h1>
              <p>You want to proceed?</p>
            </div>
            <button className="btn btn-info pull-left" onClick={onClose}>
              No
            </button>
            <button
              className="btn btn-danger pull-right"
              onClick={() => {
                dispatch(logout(token));
                onClose();
                // notify();
              }}
            >
              Yes
            </button>
          </div>
        );
      },
    });
    // dispatch(logout(token));
  };

  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  const categories = productCategories.categories;
  const user = useSelector((state) =>
    state.auth.userInfo ? state.auth.userInfo : {}
  );
  const cart = useSelector((state) =>
    state.cart.addedItems ? state.cart.addedItems : []
  );

  const [searchItem, setSearchItem] = useState("");
  //   handle field change
  const handleChange = (e) => {
    setSearchItem(e.target.value);
  };

  const handleSubmit = (e) => {
    if (e.key === "Enter") {
      searchItems();
    }
  };

  const navigate = useNavigate();

  const searchItems = () => {
    if (searchItem) {
      navigate(`search/${searchItem}`, { items: searchItem });
    } else {
      toast.error("Enter a product name first!");
    }
  };

  return (
    <header className="topnav">
      <div className="top-section bg-primaryLight justify-between">
        {/* left side icons */}
        <div className="row">
          <div className="flex-row items-center space-x-2">
            <i className="fa fa-phone text-white mr-5 font-8"></i>{" "}
            <p className="text-white font-8">+254 769 610 382</p>
          </div>
          <div className="flex-row items-center ">
            <i className="fa fa-envelope text-white font-7 mr-5"></i>{" "}
            <p className="text-white font-8">info@misana.co.ke</p>
          </div>
        </div>
        {/* right side icons */}
        <div className="flex-row items-center justify-between hide-on-phone">
          <a
            href="https://www.facebook.com/profile.php?id=100081747921165"
            target="_blank"
          >
            <i className="fa fa-facebook text-white font-8 mr-10 cursor-pointer"></i>
          </a>
          <a
            href="https://www.instagram.com/misanahealth?igsh=aDVxbGFxMmZsMGc3"
            target="_blank"
          >
            <i className="fa fa-instagram text-white font-8 mr-10 cursor-pointer"></i>
          </a>
        </div>
      </div>

      {/* top nav */}
      <div className="topnav__topbar">
        <div>
          <Link to="/">
            <img className="logo" src={logo} alt="" />
          </Link>
        </div>
        {/* search bar goes here */}
        <div className="topnav__search">
          <input
            className="topnav__search__searchInput"
            placeholder="Search items..."
            type="text"
            onChange={handleChange}
            value={searchItem}
            onKeyDown={handleSubmit}
          />
          <i onClick={searchItems} className="bx bx-search"></i>
        </div>
        <div className="topnav__profile">
          <img src={avatar} alt="" />
          Hi, {user.lastname && user.lastname ? user.lastname : "Guest"}
        </div>
        <div className="topnav__topbar__cart">
          <Link to="cart">
            <span>{cart.length}</span> Cart
          </Link>
        </div>
      </div>
      {/* lower nav */}
      <div className="topnav__lowerbar">
        <div className="main_menu">
          <span className="main_menu__item mr-1">
            <Link to="./">Home</Link>
          </span>
          {categories &&
            categories.map((item) => {
              return (
                <span key={item.category_id} className="main_menu__item mr-1">
                  <Link to={`categories/${item.category_id}`}>
                    {item.category_name}
                  </Link>
                </span>
              );
            })}
        </div>
        <div className="topnav__lowerbar__icons mobile-nav font-12">
          <i onClick={openSidebar} className="fa fa-bars"></i>

          <span className="mobile-only font-12">
            <Link to="./">
              <i title="Home" className="fa fa-home" aria-hidden="true"></i>
            </Link>
          </span>
          <span className="tablet- mr-1 font-12">
            <Link to="./">Home</Link>
          </span>
        </div>
        <div className="topnav__search product-search">
          <input
            className="topnav__search__searchInput"
            placeholder="Search items..."
            type="text"
            onChange={handleChange}
            value={searchItem}
            onKeyDown={handleSubmit}
          />
          <i onClick={searchItems} className="bx bx-search"></i>
        </div>
        {/* register */}
        {user.lastname && user.lastname ? (
          <div className="topnav__lowerbar__icons ">
            <span>
              <Link to="profile">
                <span className="auth-text font-12">My account</span>
              </Link>
            </span>
            <span onClick={logoutAction} className="logout">
              <i
                title="Logout"
                className="fa fa-sign-out"
                aria-hidden="true"
              ></i>
            </span>
          </div>
        ) : (
          <div className="topnav__lowerbar__icons ">
            <span>
              <Link to="login">
                <span className="auth-text font-12">Login</span>
                <span className="mobile-only font-12">
                  <i
                    title="Logout"
                    className="fa fa-sign-in"
                    aria-hidden="true"
                  ></i>
                </span>
              </Link>
            </span>
            <span className="">
              <Link to="register">
                <span className="auth-text font-12">Register</span>
                <span className=" mobile-only font-12">
                  <i
                    title="Logout"
                    className="fa fa-user-plus"
                    aria-hidden="true"
                  ></i>
                </span>
              </Link>
            </span>
          </div>
        )}
      </div>
      <ToastContainer theme="dark" />
    </header>
  );
};

const mapStateToProps = (state) => {
  return {
    productCategories: state.productCategories,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchCategories: () => dispatch(fetchCategories()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TopNav);
