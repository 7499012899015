import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import TextAreaInput from "../globals/TextAreaInput";
import "./checkout.scss";
// noty
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { placeAnOder } from "../../redux/cart/cartActions";

function Checkout() {
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const [location, setLocation] = useState();
  const [isOrdered, setIsOrdered] = useState(false);
  const handleChange = (e) => {
    setLocation(e.target.value);
    setIsOrdered(location ? true : false);
  };

  const user = useSelector((state) =>
    state.auth?.userInfo && state.auth.userInfo ? state.auth.userInfo : {}
  );
  const token = useSelector((state) =>
    state.auth.token ? state.auth.token : ""
  );
  const cartItems = useSelector((state) => (state.cart ? state.cart : []));

  let data = {};

  const order = () => {
    toast.info("Processig your request, please wait...");

    if (location !== "") {
      data.items = cartItems.addedItems;
      data.customer_id = user.xced_id;
      data.token = token;
      data.location = location;
      data.total = cartItems.total;

      dispatch(placeAnOder(data));
    } else {
      toast.error("Please enter your preferred delivery address");
      setIsOrdered(false);
    }
  };
  return (
    <div>
      <div className="product-wrapper__breadcrumb mb">
        <p>
          <Link className="links" to="/">
            Home
          </Link>
        </p>
        <i className="fa fa-chevron-right breadcrumb-separator"></i>
        <p>Checkout</p>
      </div>
      <div className="product-wrapper__category-title">
        <h2>Checkout</h2>
      </div>
      <div className="checkout-wrapper">
        <div className="checkout-wrapper__customer-info">
          <h3>Customer information</h3>
          <div className="checkout-wrapper__customer-info__items">
            <h4>Billing to</h4>
            <div className="checkout-wrapper__customer-info__items__billing">
              <h5>Name:</h5>
              <p>{user.firstname}</p>
            </div>
            <div className="checkout-wrapper__customer-info__items__billing">
              <h5>Email:</h5>
              <p>{user.email}</p>
            </div>
            <div className="checkout-wrapper__customer-info__items__billing">
              <h5>Phone:</h5>
              <p>{user.phone}</p>
            </div>
          </div>
          <div className="checkout-wrapper__customer-info__items">
            <h4>Shipping address</h4>
            <div className="checkout-wrapper__customer-info__items__shipping">
              <TextAreaInput
                name="location"
                onChange={handleChange}
                value={location}
                label="Enter your delivery location"
              />
            </div>
          </div>
        </div>
        <div className="checkout-wrapper__cart-summary">
          <h3>Cart summary</h3>
          <div className="checkout-wrapper__cart-summary__item">
            <h4>Your cart({cartItems.addedItems.length})</h4>
            {cartItems.addedItems &&
              cartItems.addedItems.map((item) => {
                return (
                  <div
                    key={item.product_id}
                    className="checkout-wrapper__cart-summary__item__product"
                  >
                    <h5>{item.product_name}</h5>
                    <div className="checkout-wrapper__cart-summary__item__product__summation">
                      <p>
                        {item.quantity} item(s) x Kshs.
                        {item.product_price.toFixed(2)}
                      </p>
                      <h6>
                        Kshs.{(item.quantity * item.product_price).toFixed(2)}
                      </h6>
                    </div>
                  </div>
                );
              })}
            <div className="checkout-wrapper__cart-summary__item__product">
              <div className="checkout-wrapper__cart-summary__item__product__summation">
                <p>Total</p>
                <h5>Kshs.{cartItems.total.toFixed(2)}</h5>
              </div>
            </div>
          </div>
          <div className="checkout-wrapper__cart-summary__item">
            <h4>Payment methods</h4>
            <div className="checkout-wrapper__cart-summary__item__product">
              <div className="checkout-wrapper__cart-summary__item__product__summation">
                <p>Method</p>
                <h5>Pay on order confirmation / delivery</h5>
              </div>
            </div>
          </div>
          <div className="checkout">
            <button
              disabled={isOrdered ? "" : "disabled"}
              onClick={order}
              title="Place your order"
            >
              Place your order
            </button>
          </div>
        </div>
      </div>
      <ToastContainer theme="dark" />
    </div>
  );
}

export default Checkout;
