import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import NoItems from "../globals/NoItems";
import CartItem from "./CartItem";

const Cart = () => {
  const cartItems = useSelector((state) => (state.cart ? state.cart : []));
  return (
    <div>
      <div className="product-wrapper__breadcrumb mb">
        <p>
          <Link className="links" to="/">
            Home
          </Link>
        </p>
        <i className="fa fa-chevron-right breadcrumb-separator"></i>
        <p>Cart</p>
      </div>
      <div className="product-wrapper__category-title">
        <h2>Shopping Cart</h2>
        <i>{cartItems.addedItems.length} items</i>
      </div>
      {cartItems.addedItems.length ? (
        <div className="cart-items shopping-cart">
          <div className="column-labels">
            <label className="product-image">Image</label>
            <label className="product-details">Product</label>
            <label className="product-price">Price</label>
            <label className="product-quantity">Quantity</label>
            <label className="product-removal">Remove</label>
            <label className="product-line-price">Total</label>
          </div>
          {cartItems.addedItems &&
            cartItems.addedItems.map((item) => {
              return <CartItem key={item.product_id} item={item} />;
            })}

          <div className="totals">
            <div className="totals__item">
              <label>Total</label>
              <div className="totals__value">{cartItems.total.toFixed(2)}</div>
            </div>
          </div>
          <div className="checkout">
            <Link to="/checkout">
              <button title="Checkout to place your order">Checkout</button>
            </Link>
          </div>
        </div>
      ) : (
        <NoItems />
      )}
    </div>
  );
};

export default Cart;
