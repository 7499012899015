export const FETCH_DISCOUNTS_REQUEST = "FETCH_DISCOUNTS_REQUEST";
export const FETCH_DISCOUNTS_SUCCESS = "FETCH_DISCOUNTS_SUCCESS";
export const FETCH_DISCOUNTS_FAILURE = "FETCH_DISCOUNTS_FAILURE";

export const REGISTER_DISCOUNT_REQUEST = "REGISTER_DISCOUNT_REQUEST";
export const REGISTER_DISCOUNT_SUCCESS = "REGISTER_DISCOUNT_SUCCESS";
export const REGISTER_DISCOUNT_FAILURE = "REGISTER_DISCOUNT_FAILURE";

export const UPDATE_DISCOUNT_REQUEST = "UPDATE_DISCOUNT_REQUEST";
export const UPDATE_DISCOUNT_SUCCESS = "UPDATE_DISCOUNT_SUCCESS";
export const UPDATE_DISCOUNT_FAILURE = "UPDATE_DISCOUNT_FAILURE";

export const CLEAR_STATE = "CLEAR_STATE";
