import React, { useState } from "react";
import "./main-layout.scss";
import TopNav from "../components/topnav/TopNav";
import { Outlet } from "react-router";
import SystemFooter from "../components/footer/SystemFooter";
import Sidebar from "../components/sidebar/Sidebar";
import Backdrop from "../components/backdrop/Backdrop";

const MainLayout = () => {
  const [sidebar, setSidebar] = useState(false);
  let body = document.querySelector("body");
  const toggleSidebar = () => {
    setSidebar((prevState) => !prevState);
    body.classList.toggle("hideScrolling");
  };
  return (
    <>
      <div className="main">
        <div className="main__content">
          <TopNav openSidebar={toggleSidebar} />
          <Backdrop sidebar={sidebar} closeSidebar={toggleSidebar} />
          <Outlet />
          <SystemFooter />
        </div>
      </div>
      <Sidebar sidebar={sidebar} closeSidebar={toggleSidebar} />
    </>
  );
};

export default MainLayout;
