import {
  CLEAR_STATE,
  FETCH_DISCOUNTS_FAILURE,
  FETCH_DISCOUNTS_REQUEST,
  FETCH_DISCOUNTS_SUCCESS,
  REGISTER_DISCOUNT_FAILURE,
  REGISTER_DISCOUNT_REQUEST,
  REGISTER_DISCOUNT_SUCCESS,
  UPDATE_DISCOUNT_FAILURE,
  UPDATE_DISCOUNT_REQUEST,
  UPDATE_DISCOUNT_SUCCESS,
} from "./discountActionTypes";

// set the initial state
const initialState = () => {
  return {
    loading: false,
    discounts: [],
    success: "",
    error: "",
    saving: false,
    updating: false,
  };
};

// set the reducer
const discountReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DISCOUNTS_REQUEST:
      return {
        loading: true,
      };
    case FETCH_DISCOUNTS_SUCCESS:
      return {
        loading: false,
        discounts: action.payload,
        error: "",
      };
    case FETCH_DISCOUNTS_FAILURE:
      return {
        loading: false,
        discounts: [],
        error: action.payload,
      };
    case REGISTER_DISCOUNT_REQUEST:
      return {
        saving: true,
      };
    case REGISTER_DISCOUNT_SUCCESS:
      return {
        saving: false,
        discounts: [...state.discounts, action.payload],
        error: "",
      };
    case REGISTER_DISCOUNT_FAILURE:
      return {
        saving: false,
        discounts: state,
        error: action.payload,
      };
    case UPDATE_DISCOUNT_REQUEST:
      return {
        updating: true,
      };
    case UPDATE_DISCOUNT_SUCCESS:
      return {
        ...state,
        updating: false,
        discounts: state.discounts,
        error: "",
        success: action.payload,
      };
    case UPDATE_DISCOUNT_FAILURE:
      return {
        ...state,
        updating: false,
        discounts: state.discounts,
        error: action.payload,
        success: "",
      };

    case CLEAR_STATE:
      return {
        loading: false,
        success: "",
        error: "",
        saving: false,
        updating: false,
      };
    default:
      return state;
  }
};

export default discountReducer;
