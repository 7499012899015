import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
  const auth = useSelector((state) =>
    state.auth?.isLoggedIn && state.auth.isLoggedIn
      ? state.auth.isLoggedIn
      : false
  );
  const navigate = useNavigate();

  useEffect(() => {
    // redirect to login if not logged in
    if (!auth) {
      navigate("/login");
    }
  });
  return children;
};

export default ProtectedRoute;
