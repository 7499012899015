import React from "react";
import "./textareaInput.scss";

const TextAreaInput = (props) => {
  return (
    <div className="textArea-input-holder">
      <textarea
        id={props.name}
        name={props.name}
        className="textArea-input-field"
        placeholder={props.label}
        onChange={props.onChange}
        rows="5"
        required={props.required ? props.required : ""}
        value={props.value ? props.value : ""}
      ></textarea>
      <label className="textArea-input-label">{props.label}</label>
      <p className="txt-danger title-small">{props.inputError}</p>
    </div>
  );
};

export default TextAreaInput;
