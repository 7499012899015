import React, { useEffect, useState } from "react";
import Button from "../globals/Button";
import "./auth.scss";
import TextInput from "../globals/TextInput";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
// noty
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { login } from "../../redux/auth/authActions";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = useSelector((state) =>
    state?.auth && state.auth ? state?.auth : []
  );

  useEffect(() => {
    if (userData.isLoggedIn) {
      navigate(-1);
    }
    return;
  }, [userData.isLoggedIn, navigate]);

  const inputs = { phone: "", password: "" };
  const [inputValues, setInputValues] = useState(inputs);
  const [formErrors, setformErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  //   handle field change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValues({ ...inputValues, [name]: value });
  };

  // validate the form
  const validate = (values) => {
    const errors = {};
    if (!values.phone) {
      errors.phone = "Phone is required!";
    }
    if (!values.password) {
      errors.password = "Password is required!";
    }
    return errors;
  };
  // Login
  let submitForm = (e) => {
    e.preventDefault();
    setformErrors(validate(inputValues));
    setIsSubmit(true);

    if (
      Object.keys(formErrors).length === 0 &&
      inputValues.phone !== "" &&
      inputValues.password !== ""
    ) {
      toast.info("Logging you in, please wait...");
      dispatch(login(inputValues)).then((status) => {
        if (status) {
          setIsSubmit(false);
          toast.success("Welcome back! Login was successfull.");
          window.location.href = "./";
        } else {
          toast.error("Failed to log you in. Please retry.");
          setIsSubmit(false);
        }
      });
    } else {
      toast.error("Fill in the form correctly!");
      setIsSubmit(false);
    }
  };
  return (
    <div className="auth">
      <div className="auth__box">
        <div className="auth__box__header">
          <div className="title-large font-20 text-blue">Login</div>
        </div>
        <div className="clearfix"></div>

        <form onSubmit={(e) => submitForm(e)}>
          <div className="auth__box__body">
            <TextInput
              onChange={handleChange}
              value={inputValues.email}
              inputError={formErrors.email}
              type="number"
              name="phone"
              label="Phone number"
            />
            <TextInput
              onChange={handleChange}
              value={inputValues.password}
              inputError={formErrors.password}
              type="password"
              name="password"
              label="Password"
            />
          </div>
          <div className="auth__box__footer">
            <Button
              text={
                isSubmit ? (
                  <i className="fa fa-spinner fa-spin font-12"></i>
                ) : (
                  "Sign in"
                )
              }
              disabled={isSubmit ? true : false}
              className="btn btn-success pull-right bg-blue"
              type="submit"
            />
          </div>
        </form>
        <div>
          <p className="auth__box__footer__links">
            ForgotPassword ?{" "}
            <Link className="links" to="/forgotpassword">
              {" "}
              Reset password
            </Link>
          </p>
          <p className="auth__box__footer__links">
            Not account?{" "}
            <Link className="links" to="/register">
              {" "}
              Register
            </Link>
          </p>
        </div>
      </div>
      <ToastContainer theme="dark" />
    </div>
  );
};

export default Login;
