import React, { useState } from "react";
import "./cart.scss";
import { BASE_URL } from "../../redux/api";
import { useDispatch } from "react-redux";
import {
  addQuantity,
  removeFromCart,
  subQuantity,
} from "../../redux/cart/cartActions";

const CartItem = ({ item }) => {
  let total = 0;
  const [quantity, setQuantity] = useState(item.quantity);

  const handleChange = (e) => {
    setQuantity(e.target.value);
  };

  const handleAddChange = () => {
    if (quantity < 10) {
      setQuantity(Number(quantity) + 1);
      dispatch(addQuantity(item));
    }
  };
  const handleMinusChange = () => {
    if (quantity > 1) {
      setQuantity(Number(quantity) - 1);
      dispatch(subQuantity(item));
    }
  };
  const dispatch = useDispatch();
  const removeItem = () => {
    dispatch(removeFromCart(item));
  };

  total = item.product_price * quantity;

  return (
    <div className="product">
      <div className="product-image">
        <img src={`${BASE_URL}/${item.photo}`} alt="" />
      </div>
      <div className="product-details">
        <div className="product-title">{item.product_name}</div>
        <p className="product-description">{item.product_desc}</p>
      </div>
      <div className="product-price">{item.product_price}</div>
      <div className="product-quantity">
        <div className="product-wrapper__product-details__product__info__quantity">
          <i
            onClick={handleMinusChange}
            title="Decrease quatity"
            className="fa fa-minus-circle"
          ></i>
          <input
            type="number"
            onChange={handleChange}
            value={quantity}
            name="quantity"
            hidden="hidden"
          />
          <input
            type="number"
            value={quantity}
            name="quantityHolder"
            disabled="disabled"
          />
          <i
            onClick={handleAddChange}
            title="Increase quantity"
            className="fa fa-plus-circle"
          ></i>
        </div>
      </div>
      <div className="product-removal">
        <button
          title="Remove item from cart"
          onClick={removeItem}
          className="btn remove-product"
        >
          <i className="fa fa-trash"></i>
        </button>
      </div>
      <div className="product-line-price">{total.toFixed(2)}</div>
    </div>
  );
};

export default CartItem;
