import axios from "axios";
import { BASE_URL } from "../api";
import {
  CLEAR_STATE,
  FETCH_CATEGORIES_FAILURE,
  FETCH_CATEGORIES_REQUEST,
  FETCH_CATEGORIES_SUCCESS,
  REGISTER_CATEGORY_FAILURE,
  REGISTER_CATEGORY_REQUEST,
  REGISTER_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_FAILURE,
  UPDATE_CATEGORY_REQUEST,
  UPDATE_CATEGORY_SUCCESS,
} from "./categoryActionTypes";

// set the actions

// 1. fetch the categories
export const fetchCategoriesRequest = () => {
  return {
    type: FETCH_CATEGORIES_REQUEST,
  };
};

// 2. set and store the categories in case of success
export const fetchCategoriesSuccess = (categories) => {
  return {
    type: FETCH_CATEGORIES_SUCCESS,
    payload: categories,
  };
};

// set the error message in case of an error
export const fetchCategoriesFailure = (error) => {
  return {
    type: FETCH_CATEGORIES_FAILURE,
    payload: error,
  };
};

// 4.register a category
export const registerCategoryRequest = () => {
  return {
    type: REGISTER_CATEGORY_REQUEST,
  };
};

// 5. set and store the category in case of success
export const registerCategorySuccess = (category) => {
  return {
    type: REGISTER_CATEGORY_SUCCESS,
    payload: category,
  };
};

// set the error message in caseof an error
export const registerCategoryFailure = (error) => {
  return {
    type: REGISTER_CATEGORY_FAILURE,
    payload: error,
  };
};

// update a category
export const updateCategoryRequest = () => {
  return {
    type: UPDATE_CATEGORY_REQUEST,
  };
};

// 5. set and store the category in case of success
export const updateCategorySuccess = (msg) => {
  return {
    type: UPDATE_CATEGORY_SUCCESS,
    payload: msg,
  };
};

// set the error message in caseof an error
export const updateCategoryFailure = (error) => {
  return {
    type: UPDATE_CATEGORY_FAILURE,
    payload: error,
  };
};

// clear the state
export const clearStateRequest = () => {
  return {
    type: CLEAR_STATE,
  };
};

// fetch the Categories using axios
export const fetchCategories = () => {
  return (dispatch) => {
    dispatch(fetchCategoriesRequest);
    axios
      .get(`${BASE_URL}/api/categories`)
      .then((response) => {
        const categories = response.data.categories;
        let data = [];
        for (let i = 0; i < categories.length; i++) {
          data.push({
            category_name: categories[i].category_name,
            category_desc: categories[i].category_desc,
            category_id: categories[i].category_id,
          });
        }
        dispatch(fetchCategoriesSuccess(data));
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(fetchCategoriesFailure(errorMsg));
      });
  };
};

export function clearState() {
  return function action(dispatch) {
    dispatch(clearState);
  };
}

// register category
export function registerCategory(data) {
  return function action(dispatch) {
    dispatch(registerCategoryRequest);

    const request = axios({
      method: "POST",
      url: BASE_URL + "/api/addCategory",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      data: JSON.stringify(data),
    });

    return request
      .then((response) => {
        if (response.data.status === 200) {
          const cat = response.data.data;
          const dataArray = {
            category_name: cat.category_name,
            category_desc: cat.category_desc,
            category_id: cat.category_id,
          };
          dispatch(registerCategorySuccess(dataArray));
        } else {
          const errorMsg = response.data.message;
          dispatch(registerCategoryFailure(errorMsg));
        }
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(registerCategoryFailure(errorMsg));
      });
  };
}

// update Category
export function updateCategory(data) {
  return function action(dispatch) {
    dispatch(updateCategoryRequest);

    const request = axios.put(
      BASE_URL + "/api/update_category/" + data.category_id,
      data
    );

    return request
      .then((response) => {
        if (response.data.status === 200) {
          const success = response.data.message;
          dispatch(updateCategorySuccess(success));
          window.setTimeout(function () {
            window.location = "/product-categories";
          }, 3000);
        } else {
          const errorMsg = response.data.message;
          dispatch(updateCategoryFailure(errorMsg));
        }
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(updateCategoryFailure(errorMsg));
      });
  };
}
