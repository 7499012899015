import React, { useState } from "react";
import "./categories.scss";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import TextInput from "../globals/TextInput";
import SelectInput from "../globals/SelectInput";
import ListProduct from "./ListProduct";

const Categories = () => {
  const { id } = useParams();

  const products = useSelector((state) =>
    state.products.products ? state.products.products : []
  );
  const isLoggedIn = useSelector((state) =>
    state.auth.isLoggedIn ? state.auth.isLoggedIn : false
  );
  const categories = useSelector((state) =>
    state.productCategories.categories ? state.productCategories.categories : []
  );

  const category = categories.length
    ? categories.find((e) => e.category_id === Number(id))
    : {};

  let filteredProducts = products.filter(
    (item) => item.category_id === Number(id)
  );

  let inputs = {
    filter: "",
    sort: 0,
  };

  const [searchItem, setSearchItem] = useState("");

  let [inputValues, setInputValues] = useState(inputs);

  let handleChange = (e) => {
    const { name, value } = e.target;
    setInputValues({ ...inputValues, [name]: value });
  };

  //  select input options for sorting by
  let sortOptions = [
    { value: "0", label: "None" },
    { value: "1", label: "Latest" },
    { value: "2", label: "Cheapest" },
    { value: "3", label: "Most expensive" },
  ];

  return (
    <div className="product-wrapper">
      <div className="product-wrapper__breadcrumb mb">
        <p>
          <Link className="links" to="/">
            Home
          </Link>
        </p>
        <i className="fa fa-chevron-right breadcrumb-separator"></i>
        <p>{category.category_name}</p>
      </div>
      <div className="product-wrapper__category-title">
        <h2>{category.category_name}</h2>
        <i>{filteredProducts.length} items found</i>
      </div>
      <div className="product-wrapper__display">
        <div className="product-wrapper__display__filters">
          <h4>Filter items</h4>
          <div className="product-wrapper__display__filters__option">
            <TextInput
              onChange={(event) => {
                setSearchItem(event.target.value);
                handleChange(event);
              }}
              value={inputValues.filter}
              label="Search by name"
              type="text"
              name="filter"
            />
          </div>
          <div className="product-wrapper__display__filters__option">
            <SelectInput
              label="Sort"
              onChange={handleChange}
              options={sortOptions}
              name="sort"
              value={inputValues.sort}
            />
          </div>
          <h4>Categories</h4>
          <div className="product-wrapper__display__filters__categories">
            {categories &&
              categories.map((item) => {
                let catprods = products.filter(
                  (p) => p.category_id === Number(item.category_id)
                ).length;
                return (
                  <div key={item.category_id}>
                    <Link
                      className="product-wrapper__display__filters__categories__item"
                      to={`/categories/${item.category_id}`}
                    >
                      {item.category_name}
                      <p>{catprods}</p>
                    </Link>
                  </div>
                );
              })}
          </div>
        </div>
        <div className="product-wrapper__display__products">
          {filteredProducts &&
            filteredProducts
              .filter((val) => {
                if (searchItem === "") {
                  return val;
                } else if (
                  val.product_name
                    .toLowerCase()
                    .includes(searchItem.toLocaleLowerCase())
                ) {
                  return val;
                }
                return val;
              })
              .map((item) => {
                return <ListProduct key={item.product_id} item={item} />;
              })}
        </div>
        <div
          className={isLoggedIn ? "hidden" : "product-wrapper__display__other"}
        >
          <p>Would you like to have a personalized shopping?</p>
          <div className="clearfix"></div>
          <Link to="/login" title="Login" className="btn btn-secondary">
            <i className="fa fa-sign-in"></i> Login
          </Link>
          <hr />
          <p>No account yet?</p>
          <div className="clearfix"></div>
          <Link to="/register" title="Sign up" className="btn btn-secondary">
            <i className="fa fa-sign-in"></i> Sign up
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Categories;
