import React, { useEffect } from "react";
import Slider from "../components/slider/Slider";
import Banner1 from "../assets/images/BannerImages/Banner1.jpg";
import Banner3 from "../assets/images/BannerImages/Banner3.jpg";
import Banner5 from "../assets/images/BannerImages/Banner5.jpg";
import { fetchProducts } from "../redux/products/productActions";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { BASE_URL } from "../redux/api";
import { addToCart } from "../redux/cart/cartActions";
import { truncateText } from "../components/globals/Utilities";

const Landing = ({ fetchProducts, productCategories }) => {
  const dispatch = useDispatch();
  const bannerImages = [Banner1, Banner3, Banner5];
  useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);

  let sendToCart = (item) => {
    dispatch(addToCart(item));
  };

  const backBtnClick = (id) => {
    const slider = document.querySelector("#" + id);
    slider.scrollLeft = slider.scrollLeft - 500;
  };

  const nextBtnClick = (id) => {
    const slider = document.querySelector("#" + id);
    slider.scrollLeft = slider.scrollLeft + 500;
  };

  const products = useSelector((state) =>
    state.products.products ? state.products.products : []
  );

  const displayProducts = [];

  for (let i = 0; i < productCategories?.length; i++) {
    const element = productCategories[i];
    const category = element.category_name.toLowerCase();

    let prods = products.filter(
      (item) => item.category_id === element.category_id
    );

    const catProds = [];

    for (let j = 0; j < prods?.length; j++) {
      const item = prods[j];
      catProds.push(
        <div
          key={j}
          className="flex-col space-y-3 min-w-[230px] hover:border-primaryLight hover:border h-64 bg-transparent shadow-lg overflow-hidden hover:shadow-2xl rounded-md p-2"
        >
          <Link title="Click to view" to={`product/${item.product_id}`}>
            <div className=" h-40">
              <img
                className="object-contain h-full hover:object-scale-down"
                src={BASE_URL + "/" + item.photo}
                alt=""
              />
            </div>
          </Link>
          <p className="text-color-grey-700 hover:font-bold text-sm text-center">
            {truncateText(item.product_name, 30)}
          </p>

          <div className="flex flex-row w-full h-fit items-center justify-between">
            <p className="text-primary font-bold hover:font-bold text-sm text-center">
              Ksh. {item.product_price}
            </p>
            <i
              onClick={() => sendToCart(item)}
              title="Add to cart"
              className="fa fa-cart-plus hover:text-primary cursor-pointer"
            ></i>
          </div>
        </div>
      );
    }
    displayProducts.push(
      <div
        id={`slider${element.category_id}`}
        key={i}
        className={`${
          prods?.length > 0 ? "" : "hidden"
        } py-2 flex-col my-4 space-y-4 w-full h-full overflow-hidden overflow-x-scroll scroll whitespace-nowrap scroll-smooth`}
      >
        {/* category title */}
        <div className="-mt-5 absolute">
          <p className="capitalize text-primary">{category}</p>
        </div>

        <div className="flex-row w-full h-full items-center relative space-x-3 mx-3">
          {/* left scroll button */}
          <i
            onClick={() => backBtnClick(`slider${element.category_id}`)}
            className="fa fa-chevron-left text-color-grey-600 hover:text-white text-lg cursor-pointer p-2 fixed left-8 bg-white rounded-full hover:bg-orange"
          ></i>
          <div className="flex-1 flex-row w-full h-full space-x-4">
            {catProds}
          </div>

          {/* right scroll button */}
          <i
            onClick={() => nextBtnClick(`slider${element.category_id}`)}
            className="fa fa-chevron-right text-color-grey-600 hover:text-white text-lg cursor-pointer p-2 fixed right-3 bg-white rounded-full hover:bg-orange"
          ></i>
        </div>
      </div>
    );
  }

  return (
    <div className="my-20">
      {/* slider component */}
      <Slider images={bannerImages} />
      <div className="d-flex m-10">
        <div className="card w-half">
          <span>
            <h2>Why Misana foods?</h2>
            <p>
              Misana foods offers free advice to our customers, sells variety of
              natural health products and advice on lifestyle changes and simple
              natural remedies for management of various health challenges that
              people face.
            </p>
          </span>
        </div>
        <div className="card w-half ml-10 bg-primary">
          <span>
            <h3 className="text-white title-large">Need help in ordering?</h3>
            <p className="text-secondary title-small">
              Call us on +254 769 610 382
            </p>
          </span>
          <i className="fa fa-phone text-secondary"></i>
        </div>
      </div>
      {/* products by category */}
      <div className="mx-10 my-5">
        <h3 className="title">Products by category</h3>
      </div>

      <div className="px-10">{displayProducts}</div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    products: state.products.products,
    productCategories: state.productCategories.categories,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchProducts: () => dispatch(fetchProducts()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Landing);
