import React, { useEffect, useState } from "react";
import Button from "../globals/Button";
import "./auth.scss";
import TextInput from "../globals/TextInput";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
// noty
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { login } from "../../redux/auth/authActions";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = useSelector((state) =>
    state?.auth && state.auth ? state?.auth : []
  );

  useEffect(() => {
    if (userData.isLoggedIn) {
      navigate(-1);
    }
    return;
  }, [userData.isLoggedIn, navigate]);

  const inputs = { email: "" };
  const [inputValues, setInputValues] = useState(inputs);
  const [formErrors, setformErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  //   handle field change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValues({ ...inputValues, [name]: value });
  };

  // validate the form
  const validate = (values) => {
    const errors = {};
    const regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    if (!values.email) {
      errors.email = "Email is required!";
    } else if (!regex.test(values.email)) {
      errors.email = "Please enter a valid email address!";
    }
    return errors;
  };
  // Login
  let submitForm = (e) => {
    e.preventDefault();
    setformErrors(validate(inputValues));
    setIsSubmit(true);

    if (
      Object.keys(formErrors).length === 0 &&
      inputValues.email !== "" &&
      inputValues.password !== ""
    ) {
      toast.info("Logging you in, please wait...");
      dispatch(login(inputValues));

      if (userData.success) {
        setIsSubmit(false);
      } else if (userData.error) {
        setIsSubmit(false);
      } else {
        console.log("Unknown error occured");
        setIsSubmit(false);
      }
    } else {
      toast.error("Fill in the form correctly!");
      setIsSubmit(false);
    }
  };
  return (
    <div className="auth">
      <div className="auth__box">
        <div className="auth__box__header">
          <div className="title-large font-20 text-blue">ForgotPassword</div>
        </div>
        <p align="center" className="text-danger">
          {userData.error}
        </p>
        <form onSubmit={(e) => submitForm(e)}>
          <div className="auth__box__body">
            <TextInput
              onChange={handleChange}
              value={inputValues.email}
              inputError={formErrors.email}
              type="email"
              name="email"
              label="Email"
            />
          </div>
          <div className="auth__box__footer">
            <Button
              text={
                isSubmit ? (
                  <i className="fa fa-spinner fa-spin font-12"></i>
                ) : (
                  "Sign in"
                )
              }
              disabled={isSubmit ? true : false}
              className="btn btn-success pull-right"
              type="submit"
            />
          </div>
        </form>
        <div>
          <p className="auth__box__footer__links">
            Remembered password?{" "}
            <Link className="links" to="/login">
              {" "}
              Login
            </Link>
          </p>
        </div>
      </div>
      <ToastContainer theme="dark" />
    </div>
  );
};

export default ForgotPassword;
