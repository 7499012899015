import "./assets/libs/boxicons-2.1.1/css/boxicons.min.css";
import "./scss/App.scss";
import Blank from "./pages/Blank";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import MainLayout from "./layout/MainLayout";
import Landing from "./pages/Landing";
import { Provider } from "react-redux";
import store from "./redux/Store";
import ViewProduct from "./components/products/ViewProduct";
import Login from "./components/auth/Login";
import ForgotPassword from "./components/auth/ForgotPassword";
import Register from "./components/auth/Register";
import Categories from "./components/categories/Categories";
import Cart from "./components/cart/Cart";
import Checkout from "./components/checkout/Checkout";
import ProtectedRoute from "./configs/ProtectedRoute";
import Profile from "./components/profile/Profile";
import Myorders from "./components/profile/Myorders";
import SearchItems from "./components/search/SearchItems";
import ScrollToTop from "./components/globals/ScrollToTop";

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<MainLayout />}>
            <Route index element={<Landing />} />
            <Route path="login" element={<Login />} />
            <Route path="forgotpassword" element={<ForgotPassword />} />
            <Route path="partners" element={<Blank />} />
            <Route path="cart" element={<Cart />} />
            <Route path="register" element={<Register />} />
            <Route path="search/:items" element={<SearchItems />} />
            <Route path="categories/:id" element={<Categories />} />
            <Route path="product/:id" element={<ViewProduct />} />
            <Route
              path="checkout"
              element={
                <ProtectedRoute>
                  <Checkout />
                </ProtectedRoute>
              }
            />
            <Route
              path="profile"
              element={
                <ProtectedRoute>
                  <Profile />
                </ProtectedRoute>
              }
            />
            <Route
              path="orders"
              element={
                <ProtectedRoute>
                  <Myorders />
                </ProtectedRoute>
              }
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
