import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
// noty
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { updateUser } from "../../redux/auth/authActions";
import { fetchOrders } from "../../redux/cart/cartActions";
import DataTable from "../datatable/DataTable";
import Button from "../globals/Button";
import OrderStatus from "../globals/OrderStatus";
import TextInput from "../globals/TextInput";
import "./profile.scss";

function Profile() {
  const dispatch = useDispatch();

  const user = useSelector((state) =>
    state.auth?.userInfo && state.auth.userInfo ? state.auth.userInfo : {}
  );
  const token = useSelector((state) =>
    state.auth.token ? state.auth.token : ""
  );

  useEffect(() => {
    let data = {
      customer_id: user.xced_id,
      token: token,
    };
    dispatch(fetchOrders(data));
  }, [token, user.xced_id, dispatch]);
  const userData = useSelector((state) => state.auth);

  const inputs = {
    firstname: user.firstname,
    lastname: user.lastname,
    email: user.email,
    phone: user.phone,
  };
  const [inputValues, setInputValues] = useState(inputs);
  const [formErrors, setformErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  //   handle field change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValues({ ...inputValues, [name]: value });
  };

  // validate the form
  const validate = (values) => {
    const errors = {};
    const regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    if (!values.firstname) {
      errors.firstname = "First name is required!";
    }
    if (!values.lastname) {
      errors.lastname = "Last name is required!";
    }
    if (!values.email) {
      errors.email = "Email is required!";
    } else if (!regex.test(values.email)) {
      errors.email = "Please enter a valid email address!";
    }
    if (!values.phone) {
      errors.phone = "Phone number is required!";
    }
    return errors;
  };
  let submitForm = (e) => {
    e.preventDefault();
    setformErrors(validate(inputValues));
    setIsSubmit(true);

    if (
      Object.keys(formErrors).length === 0 &&
      inputValues.firstname !== "" &&
      inputValues.lastname !== "" &&
      inputValues.email !== "" &&
      inputValues.phone !== ""
    ) {
      let xced_id = user.xced_id;
      inputValues.token = token;
      inputValues.xced_id = xced_id;
      dispatch(updateUser(inputValues));

      if (userData.success) {
        setIsSubmit(false);
        toast.info(userData.success);
      } else if (userData.error) {
        setIsSubmit(false);
      } else {
        console.log("Unknown error occured");
        setIsSubmit(false);
      }
    } else {
      toast.error("Fill in the form correctly!");
      setIsSubmit(false);
    }
  };
  const cartItems = useSelector((state) =>
    state?.cart && state.cart ? state.cart : []
  );
  const orders = cartItems.orders.slice(0, 5);

  let orderHeaders = ["Order ID", "Date", "Products", "Total", "Status"];
  let tableRows = [];
  if (cartItems.orders) {
    for (let i = 0; i < orders.length; i++) {
      tableRows.push({
        order_id: orders[i].order_id,
        created_at: moment(orders[i].created_at).format("llll"),
        products: JSON.parse(orders[i].products).length + " items",
        total: "Kshs." + orders[i].total.toFixed(2),
        status: <OrderStatus status={orders[i].order_status} />,
      });
    }
  } else {
    tableRows = [];
  }

  return (
    <div className="profile">
      <div className="product-wrapper__breadcrumb mb">
        <p>
          <Link className="links" to="/">
            Home
          </Link>
        </p>
        <i className="fa fa-chevron-right breadcrumb-separator"></i>
        <p>My account</p>
      </div>
      <div className="product-wrapper__category-title">
        <h2>My account</h2>
      </div>
      <div className="checkout-wrapper">
        <div className="checkout-wrapper__customer-info">
          <h4>Profile information</h4>
          <div className="checkout-wrapper__customer-info__items">
            <div className="checkout-wrapper__customer-info__items__billing">
              <h5>Name:</h5>
              <p>{user.firstname}</p>
            </div>
            <div className="checkout-wrapper__customer-info__items__billing">
              <h5>Email:</h5>
              <p>{user.email}</p>
            </div>
            <div className="checkout-wrapper__customer-info__items__billing">
              <h5>Phone:</h5>
              <p>{user.phone}</p>
            </div>
          </div>
          <div className="display-flex space-between">
            <h4>My recent orders</h4>{" "}
            <Link className="links" to="/orders">
              <span className="pull-right">View all</span>
            </Link>
          </div>
          <div className="checkout-wrapper__customer-info__item">
            <DataTable headers={orderHeaders} data={tableRows} />
          </div>
        </div>
        <div className="checkout-wrapper__cart-summary">
          <h4>Update account</h4>
          <div className="clearfix"></div>
          <form onSubmit={(e) => submitForm(e)}>
            <div className="auth__box__body">
              <TextInput
                onChange={handleChange}
                value={inputValues.firstname}
                inputError={formErrors.firstname}
                type="text"
                name="firstname"
                label="First name"
              />
              <TextInput
                onChange={handleChange}
                value={inputValues.lastname}
                inputError={formErrors.lastname}
                type="text"
                name="lastname"
                label="Last name"
              />
              <TextInput
                onChange={handleChange}
                value={inputValues.email}
                inputError={formErrors.email}
                type="email"
                name="email"
                label="Email"
              />
              <TextInput
                onChange={handleChange}
                value={inputValues.phone}
                inputError={formErrors.phone}
                type="number"
                name="phone"
                label="Phone number"
              />
            </div>
            <div className="auth__box__footer">
              <Button
                text={
                  isSubmit ? (
                    <i className="fa fa-spinner fa-spin font-12"></i>
                  ) : (
                    "Update"
                  )
                }
                disabled={isSubmit ? true : false}
                className="btn btn-success pull-right"
                type="submit"
              />
            </div>
          </form>
        </div>
      </div>
      <ToastContainer theme="dark" />
    </div>
  );
}

export default Profile;
