import {
  ADD_PRODUCT_FAILURE,
  ADD_PRODUCT_REQUEST,
  ADD_PRODUCT_SUCCESS,
  CLEAR_STATE,
  FETCH_PRODUCTS_FAILURE,
  FETCH_PRODUCTS_REQUEST,
  FETCH_PRODUCTS_SUCCESS,
  UPDATE_PRODUCT_FAILURE,
  UPDATE_PRODUCT_IMAGE_FAILURE,
  UPDATE_PRODUCT_IMAGE_REQUEST,
  UPDATE_PRODUCT_IMAGE_SUCCESS,
  UPDATE_PRODUCT_REQUEST,
  UPDATE_PRODUCT_SUCCESS,
} from "./productActionTypes";

// set the initial state
const initialState = () => {
  return {
    loading: false,
    products: [],
    success: "",
    error: "",
    saving: false,
    dataSaved: false,
    updating: false,
    updateImage: false,
  };
};

// set the reducer
const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PRODUCTS_REQUEST:
      return {
        loading: true,
      };
    case FETCH_PRODUCTS_SUCCESS:
      return {
        loading: false,
        products: action.payload,
        error: "",
      };
    case FETCH_PRODUCTS_FAILURE:
      return {
        loading: false,
        products: [],
        error: action.payload,
      };
    case ADD_PRODUCT_REQUEST:
      return {
        saving: true,
        dataSaved: false,
        success: "",
      };
    case ADD_PRODUCT_SUCCESS:
      return {
        saving: false,
        dataSaved: true,
        products: state.products,
        error: "",
        success: "Product has been added!",
      };
    case ADD_PRODUCT_FAILURE:
      return {
        saving: false,
        dataSaved: false,
        products: state.products,
        error: action.payload,
        success: "",
      };
    case UPDATE_PRODUCT_REQUEST:
      return {
        updating: true,
        success: "",
        dataSaved: false,
      };
    case UPDATE_PRODUCT_SUCCESS:
      return {
        ...state,
        updating: false,
        products: state.products,
        error: "",
        success: action.payload,
        dataSaved: true,
      };
    case UPDATE_PRODUCT_FAILURE:
      return {
        ...state,
        updating: false,
        products: state.products,
        error: action.payload,
        success: "",
        dataSaved: false,
      };

    case UPDATE_PRODUCT_IMAGE_REQUEST:
      return {
        updateImage: true,
        dataSaved: false,
        success: "",
      };
    case UPDATE_PRODUCT_IMAGE_SUCCESS:
      return {
        ...state,
        updateImage: false,
        dataSaved: true,
        products: state.products,
        error: "",
        success: action.payload,
      };
    case UPDATE_PRODUCT_IMAGE_FAILURE:
      return {
        ...state,
        updateImage: false,
        products: state.products,
        error: action.payload,
        success: "",
        dataSaved: false,
      };

    case CLEAR_STATE:
      return {
        loading: false,
        success: "",
        error: "",
        saving: false,
        updating: false,
        dataSaved: false,
      };
    default:
      return state;
  }
};

export default productReducer;
