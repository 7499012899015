import {
  ADD_TO_CART,
  PLACE_ORDER_FAILURE,
  PLACE_ORDER_REQUEST,
  PLACE_ORDER_SUCCESS,
  REMOVE_FROM_CART,
  SUB_QUANTITY,
  ADD_QUANTITY,
  FETCH_ORDERS_FAILURE,
  FETCH_ORDERS_SUCCESS,
  FETCH_ORDERS_REQUEST,
} from "./cartActionTypes";

const initState = {
  addedItems: [],
  total: 0,
  success: "",
  error: "",
  orders: [],
};
const cartReducer = (state = initState, action) => {
  // place order request
  if (action.type === PLACE_ORDER_REQUEST) {
    return {
      ...state,
      success: "",
      error: "",
    };
  }
  // place order request
  if (action.type === PLACE_ORDER_SUCCESS) {
    return {
      ...state,
      success: action.payload,
      addedItems: [],
      total: 0,
    };
  }
  // place order request
  if (action.type === PLACE_ORDER_FAILURE) {
    return {
      ...state,
      error: action.payload,
    };
  }
  // place order request
  if (action.type === FETCH_ORDERS_REQUEST) {
    return {
      ...state,
      success: "",
      error: "",
    };
  }
  // fetch order
  if (action.type === FETCH_ORDERS_SUCCESS) {
    return {
      ...state,
      success: action.payload.message,
      orders: action.payload.data,
    };
  }
  // fetch order failure
  if (action.type === FETCH_ORDERS_FAILURE) {
    return {
      ...state,
      error: action.payload,
    };
  }
  //Add to cart
  if (action.type === ADD_TO_CART) {
    let addedItem = {
      product_id: action.payload.product_id,
      product_name: action.payload.product_name,
      product_price: action.payload.product_price,
      product_desc: action.payload.product_desc,
      photo: action.payload.photo,
    };
    //check if the action.payload id exists in the addedItems
    let existed_item = state?.addedItems?.find(
      (item) => action.payload.product_id === item.product_id
    );
    if (existed_item) {
      addedItem.quantity += 1;
      return {
        ...state,
        // total: state.total + Number(addedItem.product_price),
      };
    } else {
      addedItem["quantity"] = 1;
      //calculating the total
      let newTotal = state.total + Number(addedItem.product_price);

      return {
        ...state,
        addedItems: [...state.addedItems, addedItem],
        total: newTotal,
      };
    }
  }
  if (action.type === REMOVE_FROM_CART) {
    let itemToRemove = state.addedItems.find(
      (item) => action.payload.product_id === item.product_id
    );
    let new_items = state.addedItems.filter(
      (item) => action.payload.product_id !== item.product_id
    );

    //calculating the total
    let newTotal =
      state.total - itemToRemove.product_price * itemToRemove.quantity;
    return {
      ...state,
      addedItems: new_items,
      total: newTotal,
    };
  }
  // INSIDE CART COMPONENT
  if (action.type === ADD_QUANTITY) {
    let addedItem = state.addedItems.find(
      (item) => item.product_id === action.payload.product_id
    );
    addedItem.quantity += 1;
    let newTotal = state.total + addedItem.product_price;
    return {
      ...state,
      total: newTotal,
    };
  }
  if (action.type === SUB_QUANTITY) {
    let addedItem = state.addedItems.find(
      (item) => item.product_id === action.payload.product_id
    );
    //if the qt == 0 then it should be removed
    if (addedItem.quantity === 1) {
      let new_items = state.addedItems.filter(
        (item) => item.id !== action.payload.id
      );
      let newTotal = state.total - addedItem.product_price;
      return {
        ...state,
        addedItems: new_items,
        total: newTotal,
      };
    } else {
      addedItem.quantity -= 1;
      let newTotal = state.total - addedItem.product_price;
      return {
        ...state,
        total: newTotal,
      };
    }
  }
  return state;
};

export default cartReducer;
