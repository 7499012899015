import {
  CLEAR_STATE,
  FETCH_CATEGORIES_FAILURE,
  FETCH_CATEGORIES_REQUEST,
  FETCH_CATEGORIES_SUCCESS,
  REGISTER_CATEGORY_FAILURE,
  REGISTER_CATEGORY_REQUEST,
  REGISTER_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_FAILURE,
  UPDATE_CATEGORY_REQUEST,
  UPDATE_CATEGORY_SUCCESS,
} from "./categoryActionTypes";

// set the initial state
const initialState = () => {
  return {
    loading: false,
    categories: [],
    success: "",
    error: "",
    saving: false,
    updating: false,
  };
};

// set the reducer
const categoriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CATEGORIES_REQUEST:
      return {
        loading: true,
      };
    case FETCH_CATEGORIES_SUCCESS:
      return {
        loading: false,
        categories: action.payload,
        error: "",
      };
    case FETCH_CATEGORIES_FAILURE:
      return {
        loading: false,
        categories: [],
        error: action.payload,
      };
    case REGISTER_CATEGORY_REQUEST:
      return {
        saving: true,
      };
    case REGISTER_CATEGORY_SUCCESS:
      return {
        saving: false,
        categories: [...state.categories, action.payload],
        error: "",
      };
    case REGISTER_CATEGORY_FAILURE:
      return {
        saving: false,
        categories: state,
        error: action.payload,
      };
    case UPDATE_CATEGORY_REQUEST:
      return {
        updating: true,
      };
    case UPDATE_CATEGORY_SUCCESS:
      return {
        ...state,
        updating: false,
        categories: state.categories,
        error: "",
        success: action.payload,
      };
    case UPDATE_CATEGORY_FAILURE:
      return {
        ...state,
        updating: false,
        categories: state.categories,
        error: action.payload,
        success: "",
      };

    case CLEAR_STATE:
      return {
        loading: false,
        success: "",
        error: "",
        saving: false,
        updating: false,
      };
    default:
      return state;
  }
};

export default categoriesReducer;
