import React from "react";
import "./viewproduct.scss";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { BASE_URL } from "../../redux/api";
import { Link } from "react-router-dom";
import { addToCart } from "../../redux/cart/cartActions";

const ViewProduct = () => {
  const products = useSelector((state) =>
    state.products.products ? state.products.products : []
  );
  const { id } = useParams();

  const dispatch = useDispatch();

  let sendToCart = () => {
    dispatch(addToCart(product));
  };

  const product = products.length
    ? products.find((e) => e.product_id === Number(id))
    : {};

  const categories = useSelector((state) =>
    state.productCategories.categories ? state.productCategories.categories : []
  );

  const category = categories.length
    ? categories.find((e) => e.category_id === Number(product.category_id))
    : {};

  return (
    <div className="product-wrapper">
      <div className="product-wrapper__breadcrumb mb">
        <p>
          <Link className="links" to="/">
            Home
          </Link>
        </p>
        <i className="fa fa-chevron-right breadcrumb-separator"></i>
        <p>
          <Link className="links" to={`/categories/${product.category_id}`}>
            {category.category_name}
          </Link>
        </p>
        <i className="fa fa-chevron-right breadcrumb-separator"></i>
        <p>{product.product_name}</p>
      </div>
      <div className="product-wrapper__product-details">
        <div className="product-wrapper__product-details__product">
          <div className="product-wrapper__product-details__product__image">
            <img src={`${BASE_URL}/${product.photo}`} alt="" />
          </div>
          <div className="product-wrapper__product-details__product__info">
            <div className="title-large">{product.product_name}</div>
            <p className="">{product.product_desc}</p>
            <hr />
            <div className="title-large">Kshs {product.product_price}</div>
            <div className="clearfix"></div>
            <button onClick={sendToCart} className="btn btn-primary">
              <i className="fa fa-cart-plus"></i> Add to cart
            </button>
            <div className="product-wrapper__product-details__info__promotions">
              <hr />
              <h4 className="text-upper">Promotions</h4>
              <p className="small-text links">
                <i className="fa fa-info"></i> Delivery availble{" "}
                <b> countrywide </b>
              </p>
            </div>
          </div>
        </div>
        <div className="product-wrapper__product-details__other">
          <h4 className="text-upper">Deliveries & Returns</h4>
          <hr />
          <div className="product-wrapper__product-details__other__item">
            <div className="product-wrapper__product-details__other__item__icon">
              <i className="fa fa-info"></i>
            </div>
            <p>We offer delivery countrywide at a fee</p>
          </div>
          <hr />
          <div className="title">Return policy</div>
          <div className="product-wrapper__product-details__other__item">
            <div className="product-wrapper__product-details__other__item__icon">
              <i className="fa fa-reply"></i>
            </div>
            <p>
              Easy and faster process{" "}
              <Link className="links" to="/return-policy">
                Read more
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewProduct;
