import axios from "axios";
import { BASE_URL } from "../api";
import { refreshToken } from "../auth/authActions";
import {
  ADD_QUANTITY,
  ADD_TO_CART,
  FETCH_ORDERS_FAILURE,
  FETCH_ORDERS_REQUEST,
  FETCH_ORDERS_SUCCESS,
  PLACE_ORDER_FAILURE,
  PLACE_ORDER_REQUEST,
  PLACE_ORDER_SUCCESS,
  REMOVE_FROM_CART,
  SUB_QUANTITY,
} from "./cartActionTypes";

// set the actions

// 1. place an order
export const placeOrderRequest = () => {
  return {
    type: PLACE_ORDER_REQUEST,
  };
};

// 2. set and store the success message in case of success
export const placeOrderSuccess = (success) => {
  return {
    type: PLACE_ORDER_SUCCESS,
    payload: success,
  };
};

// set the error message in case of an error
export const placeOrderFailure = (error) => {
  return {
    type: PLACE_ORDER_FAILURE,
    payload: error,
  };
};

// add an item to cart
export const addToCartRequest = (item) => {
  return {
    type: ADD_TO_CART,
    payload: item,
  };
};
// add quantity to an item in cart REQUEST
export const addQuantityRequest = (item) => {
  return {
    type: ADD_QUANTITY,
    payload: item,
  };
};
// subract quantity from an item in cart REQUEST
export const subQuantityRequest = (item) => {
  return {
    type: SUB_QUANTITY,
    payload: item,
  };
};
// remove an item from cart
export const removeFromCartRequest = (item) => {
  return {
    type: REMOVE_FROM_CART,
    payload: item,
  };
};

// 1. fetch orders request
export const fetchOrdersRequest = () => {
  return {
    type: FETCH_ORDERS_REQUEST,
  };
};

// 2. set and store the orders in case of success
export const fetchOrdersSuccess = (success) => {
  return {
    type: FETCH_ORDERS_SUCCESS,
    payload: success,
  };
};

// set the error message in case of an error
export const fetchOrdersFailure = (error) => {
  return {
    type: FETCH_ORDERS_FAILURE,
    payload: error,
  };
};

// --------------------- SENDING TO REDUCER -------------------
// place an order
export function placeAnOder(data) {
  return function action(dispatch) {
    dispatch(placeOrderRequest());

    const request = axios({
      method: "POST",
      url: BASE_URL + "/api/orderPlacement",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + data.token,
      },
      data: data,
    });

    return request
      .then((response) => {
        if (response.data.status === 200) {
          dispatch(placeOrderSuccess(response.data.message));
          window.location.href = "/orders";
        } else if (response.data.status === 401) {
          dispatch(refreshToken());
          window.location.href = "/login";
        } else {
          dispatch(placeOrderFailure(response.data.message));
        }
      })
      .catch((error) => {
        // dispatch(placeOrderFailure(error.message));
        dispatch(
          placeOrderFailure("Failed to place your order. please retry.")
        );
      });
  };
}

// place an order
export function fetchOrders(data) {
  return function action(dispatch) {
    dispatch(fetchOrdersRequest());

    const request = axios({
      method: "GET",
      url: BASE_URL + "/api/getCustomerOrders/" + data.customer_id,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + data.token,
      },
      data: null,
    });

    return request
      .then((response) => {
        if (response.data.status === 200) {
          dispatch(fetchOrdersSuccess(response.data));
        } else if (response.data.status === 401) {
          dispatch(refreshToken());
          window.location.href = "/login";
        } else {
          dispatch(fetchOrdersFailure(response.data.message));
        }
      })
      .catch((error) => {
        // dispatch(fetchOrdersFailure(error.message));
        dispatch(fetchOrdersFailure("Failed to load your orders"));
      });
  };
}

// add to cart
export const addToCart = (item) => {
  return (dispatch) => {
    dispatch(addToCartRequest(item));
  };
};
// add quantity to an item
export const addQuantity = (item) => {
  return (dispatch) => {
    dispatch(addQuantityRequest(item));
  };
};
// subtract quantity from an item
export const subQuantity = (item) => {
  return (dispatch) => {
    dispatch(subQuantityRequest(item));
  };
};
// remove an item from cart
export const removeFromCart = (item) => {
  return (dispatch) => {
    dispatch(removeFromCartRequest(item));
  };
};
