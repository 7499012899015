import React, { useEffect, useState } from "react";

function OrderStatus({ status }) {
  const [progress, setProgress] = useState("");

  useEffect(() => {
    if (status === 4) {
      setProgress(<i className="text-danger">Failed</i>);
    } else if (status === 3) {
      setProgress(<i className="text-success">Delivered</i>);
    } else if (status === 2) {
      setProgress(<i className="text-info">On transit</i>);
    } else {
      setProgress(<i className="text-primary">Processing</i>);
    }
  }, [status]);

  return (
    <div>
      <p>{progress}</p>
    </div>
  );
}

export default OrderStatus;
