import React from "react";
import "./categories.scss";
import { BASE_URL } from "../../redux/api";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addToCart } from "../../redux/cart/cartActions";

const ListProduct = ({ item }) => {
  const dispatch = useDispatch();
  const sendToCart = () => {
    dispatch(addToCart(item));
  };

  return (
    <div
      key={item.product_id}
      className="product-wrapper__display__products__item"
    >
      <Link title="View product" to={`/product/${item.product_id}`}>
        <div className="product-wrapper__display__products__item__image">
          <img src={`${BASE_URL}/${item.photo}`} alt="" />
        </div>
      </Link>
      <div className="product-wrapper__display__products__item__details">
        <Link className="links" to={`/product/${item.product_id}`}>
          <h4>{item.product_name}</h4>
        </Link>
        {/* <p className="small-text">Instock: {item.products_available} items</p> */}
        <div className="product-wrapper__display__products__item__details__footer">
          <h4>Kshs {item.product_price}</h4>
          <button
            onClick={sendToCart}
            title="Add to cart"
            className="btn btn-primary"
          >
            <i className="fa fa-cart-plus"></i>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ListProduct;
