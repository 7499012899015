import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "./sidebar.scss";
import logo from "../../assets/images/logo.png";

const Sidebar = ({ sidebar, closeSidebar }) => {
  const categories = useSelector((state) =>
    state.productCategories.categories ? state.productCategories.categories : []
  );
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <nav className={sidebar ? "sidebar" : "sidebar--closed"}>
      <div className="hamburger-menu">
        <ion-icon name="menu-outline" id="hamburger-icon"></ion-icon>
      </div>
      <div className="sidebar" id="sidebars">
        <div onClick={closeSidebar} className="close-icon" id="close">
          <i className="fa fa-times"></i>
        </div>
        <div className="sidebar-logo">
          <img src={logo} alt="" />
        </div>

        <ul className="links">
          {categories &&
            categories.map((item) => {
              return (
                <li
                  onClick={closeSidebar}
                  key={item.category_id}
                  className="link"
                >
                  <Link to={`categories/${item.category_id}`}>
                    <i name="home-outline" className="fa fa-link"></i>
                    {item.category_name}
                  </Link>
                </li>
              );
            })}
          <li onClick={closeSidebar} key="account" className="link">
            <Link to="/profile">
              <i name="home-outline" className="fa fa-user"></i>
              My account
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Sidebar;
