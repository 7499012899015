import "./modal.scss";

const Modal = ({ handleClose, show, children, title, footer }) => {
  const showHideClassName = show ? "modal display-block" : "modal display-none";

  return (
    <div className={showHideClassName}>
      <section className="modal-main">
        <div className="modal-main__header display-flex space-between">
          <h3>{title}</h3>
          <i onClick={handleClose} className="fa fa-close links"></i>
        </div>
        <div className="modal-main__body">{children}</div>
        <div className="modal-main__footer">{footer}</div>
      </section>
    </div>
  );
};

export default Modal;
