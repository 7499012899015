import axios from "axios";
import { BASE_URL } from "../api";
import {
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  REFRESH_TOKEN,
  REGISTER_FAILURE,
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  UPDATE_USER_FAILURE,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
} from "./authActionTypes";

// set the actions

// 1. login
export const loginRequest = () => {
  return {
    type: LOGIN_REQUEST,
  };
};

// 2. set and store the user info in case of success
export const loginSuccess = (userInfo) => {
  return {
    type: LOGIN_SUCCESS,
    payload: userInfo,
  };
};

// set the error message in case of an error
export const loginFailure = (error) => {
  return {
    type: LOGIN_FAILURE,
    payload: error,
  };
};

// Registration of the user
export const signupRequest = () => {
  return {
    type: REGISTER_REQUEST,
  };
};

// incase the registration was successful
export const signupSuccess = (user) => {
  return {
    type: REGISTER_SUCCESS,
    payload: user,
  };
};

// incase the registration was unsuccessful
export const signupFailure = (error) => {
  return {
    type: REGISTER_FAILURE,
    payload: error,
  };
};

export const logoutRequest = () => {
  return {
    type: LOGOUT_REQUEST,
  };
};
// set and store the success message in case of logout success
export const logoutSuccess = (msg) => {
  return {
    type: LOGOUT_SUCCESS,
    payload: msg,
  };
};

// set and store the error message in case of logout failure
export const logoutFailure = (error) => {
  return {
    type: LOGOUT_FAILURE,
    payload: error,
  };
};
// update a user
export const updateUserRequest = () => {
  return {
    type: UPDATE_USER_REQUEST,
  };
};

// 5. set and store the user in case of success
export const updateUserSuccess = (msg) => {
  return {
    type: UPDATE_USER_SUCCESS,
    payload: msg,
  };
};

// set the error message in caseof an error
export const updateUserFailure = (error) => {
  return {
    type: UPDATE_USER_FAILURE,
    payload: error,
  };
};

// refresh token
export const refreshToken = () => {
  return {
    type: REFRESH_TOKEN,
  };
};

// ----------------------- send to reducer -----------------

// login using axios

export function login(data) {
  return function action(dispatch) {
    dispatch(loginRequest);

    const request = axios({
      method: "POST",
      url: BASE_URL + "/api/login",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      data: JSON.stringify(data),
    });

    return request
      .then((response) => {
        if (response.data.status === 200) {
          const resp = response.data;
          dispatch(loginSuccess(resp));
          return true;
        }
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(loginFailure(errorMsg));
        return false;
      });
  };
}

// sign up function
export function signup(data) {
  return function action(dispatch) {
    dispatch(signupRequest);

    const request = axios({
      method: "POST",
      url: BASE_URL + "/api/register",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      data: JSON.stringify(data),
    });

    return request
      .then((response) => {
        if (response.data.status === 200) {
          const userInfo = response.data;
          dispatch(signupSuccess(userInfo));
          return true;
        }
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(signupFailure(errorMsg));
        return false;
      });
  };
}

export function logout(token) {
  return function action(dispatch) {
    dispatch(logoutRequest());
    const request = axios({
      method: "POST",
      url: BASE_URL + "/api/logout",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
      data: null,
    });

    return request
      .then((response) => {
        if (response.data.status === 200) {
          dispatch(logoutSuccess(response.data.message));
          window.history.back();
        }
      })
      .catch((error) => {
        // dispatch(logoutFailure(error.message));
        dispatch(logoutFailure("Failed to log you out. Retry."));
        if (error.response.status === 401) {
          dispatch(logoutSuccess("Logged out"));
        }
      });
  };
}

// update user
export function updateUser(data) {
  return function action(dispatch) {
    dispatch(updateUserRequest);

    const request = axios({
      method: "PUT",
      url: BASE_URL + "/api/update-user/" + data.xced_id,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + data.token,
      },
      data: JSON.stringify(data),
    });

    return request
      .then((response) => {
        if (response.data.status === 200) {
          dispatch(updateUserSuccess(response.data));
        } else if (response.data.status === 401) {
          dispatch(refreshToken());
          window.location.href = "/login";
        } else {
          dispatch(updateUserFailure(response.data));
        }
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(updateUserFailure(errorMsg));
      });
  };
}
