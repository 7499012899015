//
//      Combime all the reducers and export as one to the store.js
//
import { combineReducers } from "redux";
import authReducer from "./auth/authReducer";
import categoriesReducer from "./product_categories/categoryReducers";
import discountReducer from "./discounts/discountReducers";
import productReducer from "./products/productReducers";
import inventoriesReducer from "./inventory/inventoryReducers";
import cartReducer from "./cart/cartReducers";

export const rootReducer = combineReducers({
  auth: authReducer,
  productCategories: categoriesReducer,
  discounts: discountReducer,
  products: productReducer,
  inventories: inventoriesReducer,
  cart: cartReducer,
});

export default rootReducer;
